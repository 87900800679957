<script>
  import { getHost } from "@/common/app.main";
  import AppsAvailable from "../../AppsAvailable";
  import { mapActions } from "vuex";

  export default {
    components: {
      AppsAvailable,
    },
    methods: {
      ...mapActions("applications", ["setShowApps"]),
      goToStart() {
        window.scrollTo(0, 0);
        const baseAppUrl = getHost();
        const routePath = "/apps";
        const isMainApp = process.env.VUE_APP_KEY_NAME === "autologin";

        if (isMainApp) {
          this.$router.push(routePath);
          this.setShowApps();
          return;
        }

        const sstk = this.$user.SSID;
        const authLink = `${baseAppUrl}/auth/?sstk=${sstk}&redirect=${routePath}`;
        this.setShowApps();
        location.href = authLink;
      },
    },
  };
</script>

<template>
  <section class="availableApps">
    <button class="availableApps__go" @click="goToStart">
      <div class="availableApps__container">
        <iconic name="home" class="availableApps__icon" />
        <p class="availableApps__text">Home</p>
      </div>
    </button>
    <div class="availableApps__line"></div>
    <AppsAvailable />
  </section>
</template>

<style lang="scss">
  .availableApps {
    @include Flex(column);
    padding: 15px;
    min-width: 320px;
    min-height: 270px;
    border-radius: 15px;
    background: $white;
    box-shadow: 0 3px 10px 0 rgba(58, 58, 58, 0.25);
    &__go {
      @include Flex(row, flex-start);
      padding: 0 10px;
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      border: none;
      background: transparent;
      border-radius: 7px;
      transition: background-color 0.3s ease;
      cursor: pointer;
      &:hover {
        background: rgba(189, 9, 9, 0.1);
        color: $primary-color;
      }
    }
    &__line {
      height: 1px;
      background: rgba(222, 222, 222, 1);
      width: 100%;
    }
    &__container {
      @include Flex();
      gap: 15px;
      padding: 10px;
    }
    &__icon {
      font-size: 20px;
    }
    &__text {
      font-family: $newFont_semiBold;
    }
  }
</style>
